import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://umea.digitalafritidsgarden.se/login/bankid"
        >
          BankID
        </a>
        <a
          className="App-link"
          href="https://umea.digitalafritidsgarden.se/login/frejaid"
        >
          FrejaID
        </a>
        <a
          className="App-link"
          href="https://umea.digitalafritidsgarden.se/login/elevad"
        >
          ElevAD
        </a>
      </header>
    </div>
  );
}

export default App;
